@tailwind base;
@tailwind components;
@tailwind utilities{
  .all-unset {
    all: unset;
  }
};

html, body {
  background-color: #0f0f0f;
  overflow-x: hidden !important;
  overscroll-behavior-x: none;
  position: relative;
  max-width: 100vw;
  font-synthesis: none;
}
@media (max-width: 768px) {
  body {
    background-color: #0f0f0f;
    overflow-x: hidden;
  }
}
/* 스타일을 적용할 특정 섹션의 클래스 */
.first-section-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media (min-width: 640px) {
  .first-section-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .first-section-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

/* 섹션 간 여백을 추가 */

.section:last-child {
  margin-bottom: 0;
}

.policies-container {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .policies-container {
    padding: 2rem;
  }

  .section {
    padding: 2.5rem;
  }

  .title {
    font-size: 1.5rem;
  }
}

@media (max-width: 640px) {
  .policies-container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
  }

  .section {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 3rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .figure-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .figure-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}

/* figure 중앙 정렬 */
.figure-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.figure-item img {
  margin-bottom: 0.5rem;
}
